import { sample } from 'effector'
import { onFeeFetchRequired } from 'entities/fee/model/store'
import {
  $withdraw,
  $withdrawConfirmed,
  $withdrawNetwork,
  $withdrawToken,
  $withdrawWallet,
  confirmWithdrawFx,
  createWithdrawFx,
  onCloseModal,
  onConfirmWithdraw,
  onOpenModal
} from './store'
import { $activeMerchantUuid } from 'entities/merchant/model'
import { type WithdrawParams } from 'shared/api/tasks/outcome'
import { createForm } from 'effector-forms'
import { string, validation } from 'shared/lib'
import { createGate } from 'effector-react'
import { onDeleteWithdraw } from 'entities/outcome/model'

export const WithdrawGate = createGate()

sample({
  clock: onOpenModal,
  target: onFeeFetchRequired
})

export const withdrawForm = createForm({
  fields: {
    amount: {
      init: 0,
      rules: [validation.rules.required()]
    },
    address: {
      init: '',
      rules: [
        validation.rules.required(),
        {
          name: 'address_correct',
          source: $withdrawWallet,
          validator: (value, form, withdrawWallet) => {
            const regex = new RegExp(
              withdrawWallet.networkData.address_regex,
              'g'
            )
            return !string.isEmpty(value) && regex.test(value)
          }
        }
      ]
    },
    memo: {
      init: ''
    },
    notes: {
      init: ''
    }
  },
  validateOn: ['submit']
})

sample({
  clock: withdrawForm.formValidated,
  source: {
    merchantUuid: $activeMerchantUuid,
    token: $withdrawToken,
    network: $withdrawNetwork
  },
  filter: ({ token, network }) => token !== null && network !== null,
  fn: ({ merchantUuid, token, network }, form) => {
    const params: WithdrawParams = {
      amount: form.amount ?? 0,
      token: token ?? '',
      network: network ?? '',
      to_address: form.address,
      memo: form.memo !== '' ? form.memo : null,
      notes: form.notes !== '' ? form.notes : null
    }
    return { merchantUuid, params }
  },
  target: createWithdrawFx
})

sample({
  clock: onConfirmWithdraw,
  source: $withdraw,
  filter: Boolean,
  fn: (withdraw) => withdraw.uuid,
  target: confirmWithdrawFx
})

sample({
  clock: onCloseModal,
  source: { withdraw: $withdraw, confirmed: $withdrawConfirmed },
  filter: ({ withdraw, confirmed }) => !confirmed && withdraw !== null,
  fn: ({ withdraw }) => (withdraw !== null ? withdraw.uuid : ''),
  target: onDeleteWithdraw
})

sample({
  clock: onCloseModal,
  target: withdrawForm.reset
})
