import { type Uuid } from 'shared/types/common'
import { http } from '../http'
import {
  type AxiosRequestConfig,
  type AxiosResponse,
  HttpStatusCode
} from 'axios'
import type * as types from 'shared/types'

export interface RefundParams {
  amount?: number
  token?: string
  network?: string
}

export interface WithdrawParams {
  amount: number
  token: string
  network: string
  to_address: string
  memo?: string | null
  notes?: string | null
}

export interface GetOutcomesParams {
  merchantUuid: types.Uuid
  page?: number
  perPage?: number
}

export const getOutcomes = async ({
  merchantUuid,
  page,
  perPage
}: GetOutcomesParams): Promise<types.IPaginatedResponse<types.Outcome>> => {
  const params = { page, per_page: perPage }
  const response = await http.get(`/merchants/${merchantUuid}/outcomes`, {
    params
  })
  return response.data.data
}

/**
 * Инициировать возврат средств по счету на оплату (предварительный расчет)
 */
export const createRefund = async (args: {
  invoiceUuid: Uuid
  params: RefundParams
}): Promise<types.Outcome> => {
  const { invoiceUuid, params } = args
  const response = await http.post<RefundParams, AxiosResponse>(
    `/invoices/${invoiceUuid}/refund`,
    params
  )
  return response.data.data
}

/**
 * Подтвердить запрос на возврат средств
 * @param refundUuid
 */
export const confirmRefund = async (refundUuid: Uuid): Promise<boolean> => {
  const response = await http.patch<AxiosRequestConfig, AxiosResponse>(
    `/refunds/${refundUuid}/confirm`
  )
  return response.data.data
}

/**
 * Создать запрос на вывод средств (предварительный расчет)
 */
export const createWithdraw = async (args: {
  merchantUuid: Uuid
  params: WithdrawParams
}): Promise<types.Outcome> => {
  const { merchantUuid, params } = args

  const response = await http.post<WithdrawParams, AxiosResponse>(
    `/merchants/${merchantUuid}/withdraw`,
    params
  )
  return response.data.data
}

/**
 * Подтвердить запрос на вывод средств
 * @param withdrawUuid
 */
export const confirmWithdraw = async (withdrawUuid: Uuid): Promise<boolean> => {
  const response = await http.patch<AxiosRequestConfig, AxiosResponse>(
    `/withdraw/${withdrawUuid}/confirm`
  )
  return response.status === HttpStatusCode.Accepted
}

export const deleteWithdraw = async (withdrawUuid: Uuid): Promise<Uuid> => {
  const response = await http.delete<AxiosRequestConfig, AxiosResponse>(
    `/withdraw/${withdrawUuid}`
  )
  return response.data.data
}

export const deleteRefund = async (refundUuid: Uuid): Promise<Uuid> => {
  const response = await http.delete<AxiosRequestConfig, AxiosResponse>(
    `/refunds/${refundUuid}`
  )
  return response.data.data
}
